/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.goal-form-wrapper, .login-form-wrapper, .product-form-wrapper, .brand-form-wrapper {
  height: calc(100% - 64px);
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;

    width: 500px;
    position: relative;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.bem-blue {
  color: #00bff3;
}

.blue-hover {
  &:hover {
    color: #00bff3;
  }
}

.pointer {
  cursor: pointer;
}

.mat-spinner {
  position: absolute;
  top: calc(50vh - 64px - 50px);
  left: 50%;
}

.no-outline {
  outline: none;
}

.c-remove {
  display: none;
}

.c-remove.clear-all {
  display: block;
}

.c-btn {
  width: initial!important;
}

.ck-editor__editable_inline {
  line-height: 22px;
  height: 300px;
  margin-bottom: 1.25rem;

  ol, ul {
    list-style: disc outside none;
  }

  ol {
    list-style-type:decimal;
  }
}
